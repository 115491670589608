import { styled } from "styled-components";
import { SettingsFormStandard } from "../../../styled/forms/SettingsFormStandard";
import {
  DropDownIconOutlined,
  FormButton,
  OutlinedIconBoxWithButton,
  TextFieldOutlined,
} from "../../../styled/input/Input";
import { useContext, useEffect, useState } from "react";
import DepositModal from "../../../modals/DepositModal";
import EditDepositAddressModal from "../../../modals/EditDepositAddressModal";
import AddDepositAddressModal from "../../../modals/AddDepositAddressModal";
import { context } from "../../../context/context";
import CircularLoader from "../../../styled/loaders/CircularLoader";
import { db } from "../../../firebase/firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { Skeleton } from "@mui/material";
import Toast from "../../../hooks/Toast";
import EditCopyLockAddressModal from "../../../modals/EditCopyLockAddressModal";

const CopyExpertSettings = () => {
  const { copySettings } = useContext(context);

  const [openModal, setOpenModal] = useState(false);

  // edit Address
  const [editAddress, setEditAddress] = useState(false);

  const [selectedEditAddress, setSelectedEditAddress] = useState(undefined);
  const [selectedEditOption, setSelectedEditOption] = useState(undefined);

  function handleEditAddress(address) {
    setSelectedEditAddress(address);
    setEditAddress(true);
  }

  const [isLoading, setIsLoading] = useState(true);

  const [lockPage, setLockPage] = useState(undefined);
  const [paymentAmount, setPaymentAmount] = useState(undefined);
  const [paymentAddress, setPaymentAddress] = useState(undefined);
  const [qr, setQr] = useState(undefined);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  useEffect(() => {
    if (copySettings) {
      setLockPage(copySettings.lockPage);
      setPaymentAmount(copySettings.paymentAmount);
      setPaymentAddress(copySettings.paymentAddress);

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [copySettings]);

  const [isSavingCopyExpertSettings, setIsSavingCopyExpertSettings] =
    useState(false);

  //   handleAmount
  function handleAmount(e) {
    const { value } = e.target;

    if (value) {
      setPaymentAmount(Number(value));
    } else {
      setPaymentAmount(paymentAmount);
    }
  }

  async function handleSaveCopySettings() {
    setIsSavingCopyExpertSettings(true);

    const q = doc(db, "admin", "copy");
    try {
      await updateDoc(q, {
        lockPage,
        paymentAddress,
        paymentAmount,
      });
      setIsSavingCopyExpertSettings(false);
      // console.log("saved");
      setToastType("success");
      setToastMessage("Updated successfully");
      setOpenToast(true);
      // toast.success("Updated successfully.");
      // setIsEditing(false);
    } catch (error) {
      console.log(error);
      setIsSavingCopyExpertSettings(false);
      setToastType("error");
      setToastMessage("Could not update. Please try again later");
      setOpenToast(true);
      // toast.error("Could not update. Please try again later");
    }
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <CopyExpertSettingsStandard>
        {editAddress && (
          <EditCopyLockAddressModal
            open={{ editAddress, setEditAddress }}
            selected={selectedEditAddress}
          />
        )}

        <SettingsFormStandard>
          <div className="header">
            <p className="title">Copy Expert</p>
            <p className="subtext">
              Manage your copy expert settings, edit amount and address.
            </p>
          </div>

          {isLoading ? (
            <div className="form">
              {/* Regular deposit */}
              <div className="section">
                <div className="section_intro">
                  <p className="title">
                    {" "}
                    <Skeleton
                      variant="rounded"
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "20%",
                      }}
                    />
                  </p>
                  <p className="subtext">
                    <Skeleton
                      variant="rounded"
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "60%",
                      }}
                    />
                  </p>
                </div>
                <div className="form_item">
                  <DropDownIconOutlined className="variant">
                    <div className="wrapper">
                      <label for="country" className="label">
                        <Skeleton
                          variant="rounded"
                          sx={{
                            backgroundColor: "rgba(27, 31, 45)",
                            maxWidth: "20%",
                          }}
                        />
                      </label>
                      {/* <div className="content">
                       
                      </div> */}
                      <Skeleton
                        variant="rounded"
                        height={40}
                        sx={{
                          backgroundColor: "rgba(27, 31, 45)",
                          // maxWidth: "20%",
                        }}
                      />
                    </div>
                  </DropDownIconOutlined>
                </div>
              </div>
            </div>
          ) : (
            <div className="form">
              {/* Regular deposit */}
              <div className="section">
                {/* Crypto */}
                <div className="form_item">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "1px solid #222739",
                      backgroundColor: "rgba(27, 31, 45, 0.3)",
                      borderRadius: "12px",
                      padding: "16px 24px",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <p
                        style={{
                          color: "#fff",
                          fontWeight: "500",
                          lineHeight: "28px",
                        }}
                      >
                        Lock page
                      </p>
                      <p
                        style={{
                          color: "#bac2de",
                          fontSize: "14px",
                          lineHeight: "24px",
                        }}
                      >
                        Lock copy experts page
                      </p>
                    </span>

                    <ToggleSwitch
                      className={lockPage && "toggled"}
                      onClick={() => setLockPage(!lockPage)}
                    >
                      <div className="toggle"></div>
                    </ToggleSwitch>
                  </div>
                </div>

                {lockPage && (
                  <div className="form_item">
                    <p className="label">Amount to pay</p>
                    <div className="content">
                      <TextFieldOutlined className="variant">
                        <input
                          type="number"
                          placeholder={paymentAmount || "Amount to pay"}
                          onChange={handleAmount}
                        />
                      </TextFieldOutlined>
                    </div>
                  </div>
                )}

                {lockPage && (
                  <div className="form_item">
                    <div className="address">
                      <div className="content">
                        <OutlinedIconBoxWithButton className="variant">
                          <div className="left">
                            <img
                              src={`./asseticons/BTC.svg`}
                              style={{ marginLeft: "12px" }}
                              // ./asseticons/${selectedMethod}.svg

                              alt=""
                            />
                            <input
                              type="text"
                              placeholder={
                                paymentAddress || "Bitcoin address for payment"
                              }
                              disabled
                            />
                          </div>
                          <button
                            onClick={() => handleEditAddress(paymentAddress)}
                          >
                            Edit address
                          </button>
                        </OutlinedIconBoxWithButton>
                      </div>
                    </div>
                  </div>
                )}

                <br />
              </div>

              <FormButton
                disabled={
                  isSavingCopyExpertSettings ||
                  !paymentAmount ||
                  !paymentAddress
                }
                className={
                  (isSavingCopyExpertSettings ||
                    !paymentAmount ||
                    !paymentAddress) &&
                  "disabled"
                }
                onClick={handleSaveCopySettings}
              >
                {isSavingCopyExpertSettings ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FormButton>
            </div>
          )}
        </SettingsFormStandard>
      </CopyExpertSettingsStandard>
    </>
  );
};

const ToggleSwitch = styled.button`
  height: 24px;
  width: 48px;
  max-width: 48px;
  padding: 0;
  background-color: #222739;

  border-radius: 48px;
  display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  /* margin: 0; */

  &.toggled {
    background-color: white;
  }

  .toggle {
    width: 22px;
    height: 22px;
    margin-left: 2px;
    border-radius: 100%;
    place-self: center;
    /* padding: 2px; */
    transition: all 300ms ease-in-out;
    background-color: #07080d;
  }

  &.toggled .toggle {
    transform: translateX(20px);
    margin-left: 4px;
  }
`;

const CopyExpertSettingsStandard = styled.div`
  .form_item {
    margin-top: 24px;
  }

  .mini_form_item {
    margin-top: 12px;
  }
`;

export default CopyExpertSettings;
