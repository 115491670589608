import { styled } from "styled-components";
import { SettingsFormStandard } from "../../../styled/forms/SettingsFormStandard";
import {
  FormButton,
  PasswordBox,
  TextBox,
  TextFieldOutlined,
} from "../../../styled/input/Input";
import Toast from "../../../hooks/Toast";
import { useEffect, useRef, useState } from "react";
import { db } from "../../../firebase/firebase";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import CircularLoader from "../../../styled/loaders/CircularLoader";
import { siteSettings } from "../../../static";

const SecurityManage = ({ user }) => {
  const { userDetails, setUserDetails } = user;
  const { id } = userDetails;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDeleteUser() {
    const profile = doc(db, "users", id);
    await deleteDoc(profile)
      .then(() => {
        // deleteUserAdmin();
        // console.log("deleted");
        // deleteUserData();
        setIsDeleting(false);
        setToastType("success");
        setToastMessage("User successfully deleted");
        setOpenToast(true);
      })
      .catch((error) => {
        console.log(error);
        setIsDeleting(false);
        setToastType("error");
        setToastMessage(
          "There was an error with your request. Please try again later"
        );
        setOpenToast(true);
      });
  }

  async function deleteUserAdmin() {
    setIsDeleting(true);
    const url = `${siteSettings.extraServerLink}/delete`;

    const details = {
      user: id,
    };

    const base = {
      details,
    };

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          console.log("res", response);
          handleDeleteUser();
          // setIsDeleting(false);
          // setToastType("success");
          // setToastMessage("User successfully deleted");
          // setOpenToast(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsDeleting(false);
        setToastType("error");
        setToastMessage("Failed to delete user. Please try again later");
        setOpenToast(true);
      });
  }

  // email
  const [email, setEmail] = useState(userDetails.email);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const emailRef = useRef();
  // extra function for email
  function handleEmailAddress(e) {
    const { value } = e.target;

    if (value) {
      setEmail(value);
    } else {
      setEmail(email);
    }
  }

  // withdrawalCode
  const [withdrawalCode, setWithdrawalCode] = useState(
    userDetails.withdrawalCode
  );
  const [isChangingWithdrawalCode, setIsChangingWithdrawalCode] =
    useState(false);
  const withdrawalCodeRef = useRef();
  // extra function for email
  function handleWithdrawalCode(e) {
    const { value } = e.target;

    if (value) {
      setWithdrawalCode(Number(value));
    } else {
      setWithdrawalCode(Number(withdrawalCode));
    }
  }

  async function changeWithdrawalCode() {
    setIsChangingWithdrawalCode(true);
    const profile = doc(db, "users", id);
    await updateDoc(profile, {
      withdrawalCode: Number(withdrawalCode),
    })
      .then(() => {
        // console.log("profile saved");
        setIsChangingWithdrawalCode(false);
        setToastType("success");
        setToastMessage("Updated successfully");
        setOpenToast(true);
      })
      .catch((error) => {
        console.log("error", error);
        setIsChangingWithdrawalCode(false);
        setToastType("error");
        setToastMessage("Failed to update. Please try again later");
        setOpenToast(true);
      });
  }

  //

  async function handleEmailAdmin() {
    setIsChangingEmail(true);
    const url = `${siteSettings.extraServerLink}/email`;

    const details = {
      user: id,
      email,
    };

    const base = {
      details,
    };

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          if (emailRef) {
            emailRef.current.value = email;
          }
          console.log("res", response);
          setIsChangingEmail(false);
          setToastType("success");
          setToastMessage("Email updated deleted");
          setOpenToast(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsChangingEmail(false);
        setToastType("error");
        setToastMessage("Failed to update email. Please try again later");
        setOpenToast(true);
      });
  }

  // password
  const [password, setPassword] = useState(userDetails.userPass);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const passwordRef = useRef();

  // handlePasswordValue
  function handlePasswordValue(e) {
    const { value } = e.target;

    if (value) {
      setPassword(value);
    } else {
      setPassword(password);
    }
  }

  async function handlePasswordAdmin() {
    setIsChangingPassword(true);
    const url = `${siteSettings.extraServerLink}/password`;

    const details = {
      user: id,
      password,
    };

    const base = {
      details,
    };

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          if (passwordRef) {
            passwordRef.current.value = password;
          }
          console.log("res", response);
          setIsChangingPassword(false);
          setToastType("success");
          setToastMessage("Password updated deleted");
          setOpenToast(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsChangingPassword(false);
        setToastType("error");
        setToastMessage("Failed to update password. Please try again later");
        setOpenToast(true);
      });
  }

  const [phrase, setPhrase] = useState(userDetails.secretPhrase);

  // useEffect(() => {
  //   console.log(user);
  // }, []);

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <SecuritySettingsStandard>
        <SettingsFormStandard>
          <div className="form">
            <div className="section">
              <div className="section_intro">
                <p className="label">Security</p>
                <p className="subtext">
                  To perform actions like change user password, change user
                  email or delete account, you have to log in to the user's
                  account. The user's details are shown below:
                </p>
              </div>

              <div className="form_item">
                <p className="label">User Email</p>
                <div className="content">
                  <TextFieldOutlined className="variant">
                    <input
                      type="email"
                      placeholder={email}
                      value={email}
                      disabled
                      // onChange={handleEmailAddress}
                    />
                  </TextFieldOutlined>
                </div>
              </div>

              <div className="form_item">
                <p className="label">User password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input
                      type="text"
                      placeholder={password}
                      value={password}
                      disabled
                    />
                  </PasswordBox>
                </div>
              </div>

              {/* <div className="form_item">
                <p className="label">New password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input
                      type="text"
                      placeholder="Password (Min. of 6 characters)"
                    />
                  </PasswordBox>
                </div>
              </div> */}

              {/* <div className="form_item">
                <p className="label">Confirm new password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input type="text" placeholder="Retype your password" />
                  </PasswordBox>
                </div>
              </div> */}

              {/* <FormButton>
                <p>Save</p>
              </FormButton> */}
            </div>

            {/* email */}
            <div className="section">
              <div className="section_intro">
                <p className="label">Email</p>
                <p className="subtext">Manage user email address.</p>
              </div>

              <div className="form_item">
                <p className="label">Email</p>
                <div className="content">
                  <TextFieldOutlined className="variant">
                    <input
                      type="email"
                      placeholder={email}
                      value={email}
                      onChange={handleEmailAddress}
                      ref={emailRef}
                    />
                  </TextFieldOutlined>
                </div>
              </div>

              <FormButton
                onClick={handleEmailAdmin}
                disabled={
                  isChangingEmail ||
                  isChangingPassword ||
                  isDeleting ||
                  !email ||
                  isChangingWithdrawalCode
                }
                className={
                  (isChangingEmail ||
                    isChangingPassword ||
                    isDeleting ||
                    !email ||
                    isChangingWithdrawalCode) &&
                  "disabled"
                }
              >
                {isChangingEmail ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FormButton>
            </div>

            {/* password */}
            <div className="section">
              <div className="section_intro">
                <p className="label">Password</p>
                <p className="subtext">Edit user password.</p>
              </div>

              <div className="form_item">
                <p className="label">New password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input
                      type="password"
                      ref={passwordRef}
                      onChange={handlePasswordValue}
                      placeholder="New password (Min. of 6 characters)"
                    />
                  </PasswordBox>
                </div>
              </div>

              <FormButton
                disabled={
                  !password ||
                  isChangingPassword ||
                  isChangingEmail ||
                  isDeleting ||
                  isChangingWithdrawalCode
                }
                className={
                  (!password ||
                    isChangingPassword ||
                    isChangingEmail ||
                    isDeleting ||
                    isChangingWithdrawalCode) &&
                  "disabled"
                }
                onClick={handlePasswordAdmin}
              >
                {isChangingPassword ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FormButton>
            </div>

            {/* phrases */}
            <div className="section">
              <div className="section_intro">
                <p className="label">Recovery phrase</p>
                <p className="subtext">Manage user secret recovery phrase.</p>
              </div>

              <div className="form_item">
                <TextBox className="scrollbar-hide">
                  <label htmlFor="address">Recovery phrase:</label>
                  <br />
                  <textarea
                    type="text"
                    cols="30"
                    rows="5"
                    defaultValue={`${
                      phrase?.length > 0 ? phrase : "Secret recovery phrase"
                    } `}
                    // onChange={handlePhrase}
                  ></textarea>
                </TextBox>
              </div>

              {/* <FormButton
                disabled={
                  !password ||
                  isChangingPassword ||
                  isChangingEmail ||
                  isDeleting ||
                  isChangingWithdrawalCode
                }
                className={
                  (!password ||
                    isChangingPassword ||
                    isChangingEmail ||
                    isDeleting ||
                    isChangingWithdrawalCode) &&
                  "disabled"
                }
                onClick={handlePasswordAdmin}
              >
                {isChangingPassword ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FormButton> */}
            </div>

            {/* delete */}
            <div className="section">
              <div className="section_intro">
                <p className="title">Delete account</p>
                <p className="subtext">
                  Once you delete this account, there is no going back. Please
                  be certain.
                </p>
              </div>

              <div className="form_item">
                <div className="content">
                  <button
                    className={
                      isDeleting ? "delete_button blink_me" : "delete_button"
                    }
                    onClick={deleteUserAdmin}
                  >
                    {isDeleting ? (
                      <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                    ) : (
                      <p> Delete account</p>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="section">
              <div className="section_intro">
                <p className="title">Delete account</p>
                <p className="subtext">
                  Once you delete your account, there is no going back. Please
                  be certain.
                </p> 
              </div>

              <div className="form_item">
                <div className="content">
                  <button className="delete_button">Delete your account</button>
                </div>
              </div>
            </div> */}
          </div>
        </SettingsFormStandard>
      </SecuritySettingsStandard>
    </>
  );
};

const SecuritySettingsStandard = styled.div`
  .form_item {
    margin-top: 24px;
  }

  .multi_factor {
    margin-top: 48px;
  }

  .blink_me {
    animation: blinker 2s linear infinite;
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }

  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }
`;

export default SecurityManage;
