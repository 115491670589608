import { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { FilledButton, FullButton, TextBox } from "../styled/input/Input";
import CircularLoader from "../styled/loaders/CircularLoader";
import { db, storage } from "../firebase/firebase";
import { deleteField, doc, setDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { context } from "../context/context";
import Toast from "../hooks/Toast";

const EditDepositAddressModal = ({ open, selected, type }) => {
  const { editAddress, setEditAddress } = open;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState(undefined);
  const [symbol, setSymbol] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [qr, setQR] = useState(undefined);
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const { dispatch } = useContext(context);
  const [tempUrl, setTempUrl] = useState("");

  const imageRef = useRef();

  function handleImageChange() {
    imageRef.current.click();
  }

  const [memo, setMemo] = useState(undefined);
  function handleMemo(e) {
    const { value } = e.target;

    if (value) {
      setMemo(value);
    } else {
      setMemo("");
    }
  }

  const [tag, setTag] = useState(undefined);
  function handleTag(e) {
    const { value } = e.target;

    if (value) {
      setTag(value);
    } else {
      setTag("");
    }
  }

  const [network, setNetwork] = useState(undefined);
  function handleNetwork(e) {
    const { value } = e.target;

    if (value) {
      setNetwork(value);
    } else {
      setNetwork("");
    }
  }

  const [note, setNote] = useState(undefined);
  function handleNote(e) {
    const { value } = e.target;

    if (value) {
      setNote(value);
    } else {
      setNote("");
    }
  }

  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
      const url = URL.createObjectURL(file);
      setTempUrl(url);
    }
  }

  useEffect(() => {
    if (selected && type) {
      const { name, symbol, address, QR, tag, network, memo, note } = selected;

      if (network) {
        setNetwork(network);
      }

      if (tag) {
        setTag(tag);
      }

      if (memo) {
        setMemo(memo);
      }

      if (note) {
        setNote(note);
      }

      setName(name);
      setSymbol(symbol);
      setAddress(address);
      setQR(QR);
      setIsLoading(false);
    }
  }, [selected, type]);

  const [isSaving, setIsSaving] = useState(false);

  async function handleSave() {
    setIsSaving(true);

    if (imageLink) {
      submitQR();
    } else {
      handleEditAddress();
    }
  }

  async function submitQR() {
    // setIsUploadingVerification(true);
    if (imageLink) {
      const storageRef = ref(storage, imageName + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            // submitVerification(downloadURL);
            handleEditAddressQR(downloadURL);
          });
        }
      );
    }
  }

  async function handleEditAddressQR(url) {
    let namekey;

    switch (type) {
      case "Regular":
        namekey = `Regular.addresses.${symbol}`;
        break;
      case "Signals":
        namekey = `Signals.addresses.${symbol}`;
      default:
        break;
    }

    // const namekey = type === "Regular "`Regular.addresses.${symbol}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: url,
          address,
          name,
          symbol,
          tag: tag || null,
          memo: memo || null,
          note: note || null,
          network: network || null,
        },
      });
      switchType();
    } catch (error) {
      setIsSaving(false);
      console.log(error);
      setToastType("error");
      setToastMessage("Could not save. Please try again later");
      setOpenToast(true);
      // toast.error("Could not update. Please try again later");
    }
  }

  async function handleEditAddress() {
    let namekey;

    switch (type) {
      case "Regular":
        namekey = `Regular.addresses.${symbol}`;
        break;
      case "Signals":
        namekey = `Signals.addresses.${symbol}`;
      default:
        break;
    }

    // const namekey = type === "Regular "`Regular.addresses.${symbol}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: qr,
          address,
          name,
          symbol,
          tag: tag || null,
          memo: memo || null,
          note: note || null,
          network: network || null,
        },
      });
      switchType();
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      setToastType("error");
      setToastMessage("Could not save. Please try again later");
      setOpenToast(true);
    }
  }

  async function switchType() {
    const namekey = `Signals.type`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: "manual",
      });
      setIsSaving(false);
      // console.log("saved");

      setToastType("success");
      setToastMessage("Successfully edited address");
      setOpenToast(true);

      setTimeout(() => {
        setEditAddress(false);
      }, 600);
      // console.log("error", error);
    } catch (error) {
      // console.log(error);
      setIsSaving(false);

      setToastType("error");
      setToastMessage("Could not save. Please try again later");
      setOpenToast(true);
      // console.log("error", error);
    }
  }

  function handleAddress(e) {
    const value = e.target.value;

    if (value) {
      setAddress(value);
    } else {
      setAddress(address);
    }
  }

  // delete
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    setIsDeleting(true);

    let namekey;

    switch (type) {
      case "Regular":
        namekey = `Regular.addresses.${symbol}`;
        break;
      case "Signals":
        namekey = `Signals.addresses.${symbol}`;
      default:
        break;
    }

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: deleteField(),
      });

      setIsDeleting(false);
      setToastType("success");
      setToastMessage("Deleted successfully");
      setOpenToast(true);
      setTimeout(() => {
        setEditAddress(false);
      }, 600);
      // switchType();
    } catch (error) {
      setIsDeleting(false);
      setToastType("error");
      setToastMessage("Could not delete. Please try again later");
      setOpenToast(true);
    }
  }

  return (
    <Modal
      open={editAddress}
      onClose={() => setEditAddress(false)}
      style={{
        display: "flex",
        placeContent: "center",
        zIndex: "10001",
        padding: "12px",
      }}
    >
      <>
        {openToast && (
          <Toast
            open={{ openToast, setOpenToast }}
            message={toastMessage}
            type={toastType}
          />
        )}

        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Edit {name ? name : " "} Address</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setEditAddress(!editAddress)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          {isLoading ? (
            <div
              style={{
                backgroundColor: "#151823",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                padding: "60px",
              }}
            >
              <CircularLoader
                bg="rgba(12, 108, 243, 0.2)"
                size="28"
                color="#0C6CF2"
              />
            </div>
          ) : (
            <div className="modal_content">
              <div className="top">
                <div className="deposit_bottom">
                  <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Address:</label>
                    <br />
                    <input
                      type="text"
                      placeholder={address}
                      onChange={handleAddress}
                    />
                  </TextBox>

                  <br />

                  {/* <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Tag(Optional):</label>
                    <br />
                    <input
                      type="text"
                      placeholder={tag || "290394"}
                      onChange={handleTag}
                    />
                  </TextBox> */}

                  {/* <br /> */}
                  {/* <br /> */}

                  <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Memo(Optional):</label>
                    <br />
                    <input
                      type="text"
                      placeholder={memo || "3748329"}
                      onChange={handleMemo}
                    />
                  </TextBox>

                  <br />

                  {/* <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Deposit note:</label>
                    <br />
                    <textarea
                      type="text"
                      cols="30"
                      rows="5"
                      placeholder={note || `Deposit note`}
                      onChange={handleNote}
                    ></textarea>
                  </TextBox> */}

                  {/* <br /> */}
                  {/* <br /> */}

                  <TextBox className="scrollbar-hide">
                    <label htmlFor="address">Network(Optional):</label>
                    {/* <br /> */}
                    <input
                      type="text"
                      placeholder={network || "ERC20"}
                      onChange={handleNetwork}
                    />
                  </TextBox>

                  <br />

                  <div
                    className="qr-code"
                    style={{
                      margin: "40px 0px",
                      height: "250px",
                      border: "1px solid #222739",
                      borderRadius: "12px",
                      display: "grid",
                      cursor: "pointer",
                      placeContent: "center",
                      margin: "40px 0px",
                      position: "relative",
                    }}
                    onClick={handleImageChange}
                  >
                    {/* <img
                    src="./assets/misc/info.svg"
                    alt=""
                    className="error_inform"
                    id="popcorn"
                    style={{
                      position: "absolute",
                      right: "-30px",
                    }}
                  /> */}

                    {qr && !tempUrl && (
                      <span
                        style={{
                          maxHeight: "250px",
                          overflow: "hidden",
                          padding: "8px",
                          height: "100%",
                        }}
                      >
                        <img
                          style={{ margin: "0px" }}
                          src={qr}
                          alt=""
                          className="qr-code"
                        />
                      </span>
                    )}

                    {qr && tempUrl && (
                      <span
                        style={{
                          maxHeight: "250px",
                          overflow: "hidden",
                          padding: "8px",
                          height: "100%",
                        }}
                      >
                        <img
                          style={{ margin: "0px" }}
                          src={tempUrl}
                          alt=""
                          className="qr-code"
                        />
                      </span>
                    )}

                    {!qr && tempUrl && (
                      <span
                        style={{
                          maxHeight: "250px",
                          overflow: "hidden",
                          padding: "8px",
                          height: "100%",
                        }}
                      >
                        <img
                          style={{ margin: "0px" }}
                          src={tempUrl}
                          alt=""
                          className="qr-code"
                        />
                      </span>
                    )}

                    {!qr && !tempUrl && (
                      <p
                        style={{
                          padding: "8px",
                          color: "white",
                          lineHeight: "20px",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#d4dcf7",
                        }}
                      >
                        Tap here to add or change QR code. Leave empty to save
                        address without QR code.
                      </p>
                    )}

                    <input
                      type="file"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        pointerEvents: "none",
                        top: "0",
                        left: "0",
                        height: "100%",
                        maxWidth: "100%",
                      }}
                      ref={imageRef}
                      onChange={handleImageURL}
                      accept="image/png, image/gif, image/jpeg, image/svg"
                    />
                  </div>
                  {/*  */}
                </div>
              </div>

              <div
                className="buttons"
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <FullButton
                  onClick={handleDelete}
                  disabled={isSaving || isDeleting}
                  className={isSaving || isDeleting ? "disabled" : "delete"}
                >
                  {isDeleting ? (
                    <div style={{ padding: "8px" }}>
                      <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                    </div>
                  ) : (
                    <p>Delete</p>
                  )}
                </FullButton>

                <FullButton
                  disabled={isSaving || isDeleting}
                  onClick={handleSave}
                  className={(isSaving || isDeleting) && "disabled"}
                >
                  {isSaving ? (
                    <div style={{ padding: "8px" }}>
                      <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                    </div>
                  ) : (
                    <p>Save</p>
                  )}
                </FullButton>
              </div>
            </div>
          )}
        </ModalStandard>
      </>
    </Modal>
  );
};

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .details_wrapper {
    padding: 24px 0px;
    padding-top: 12px;
  }

  .details {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
  }

  .title {
    color: #bac2de;
  }

  .detail {
    color: white;
  }

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }

  .bottom button:hover {
    background-color: #ff3344;
  }

  .bottom {
    margin-top: 32px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    height: fit-content;
    max-height: 90vh;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow-y: scroll;

    .bottom button {
      margin: 0;
    }

    .top {
      margin-bottom: 52px;
    }

    .bottom {
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 12px 24px;
      height: fit-content;
      background-color: #151823;
      z-index: 999;
      border: none;
      outline: none;
      /* display: none; */
    }
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }
`;

export default EditDepositAddressModal;
